export default class Utils {
  
  public static splitArrayIntoChunks<T>(array: T[], n: number): T[][] {
    const result: T[][] = [];

    for(let i = 0; i < array.length; i += n) {
      result.push(array.slice(i, i + n));
    }

    return result;
  }

  public static convertB64ToImage(b64String: string, fileNameWithoutExtension: string): File {
    const contentType = b64String.split('data:')[0];
    const data = b64String.split('base64,')[1];
    const blob: Blob = this.b64toBlob(data, contentType);
    const imageType = b64String.substring(b64String.indexOf('/') + 1, b64String.indexOf(';base64'));

    return new File([blob], `${fileNameWithoutExtension}_${this.getRandomNumbers()}.${imageType}`);
  }

  public static b64toBlob(b64Data: string, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays: any[] = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  public static getRandomNumbers(): number {
    return Math.floor(100000 + Math.random() * 900000);
  }

}
